@charset "UTF-8";
/* 메인 상단 */
.daum-postcode {
  display: block;
  position: absolute;
  top: calc(50% - 300px);
  left: calc(50% - 300px);
  width: 600px;
  height: 600px;
  padding: 7px;
  z-index: 10; }
  .daum-postcode .btn-close {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 11; }
