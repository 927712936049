@charset "UTF-8";
/* 고객 리스트 */
.section-box:nth-child(1) {
  margin: 0 0 30px 0; }

.user-search-box tbody tr th {
  width: 200px;
  background-color: #E8ECF4; }

.user-search-box tbody tr td .search-input-box select {
  width: 200px;
  height: 38px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  padding: 0 20px 0 7px;
  background-position: right 7px center;
  font-size: 12px;
  font-weight: 400;
  color: #74788D; }

.user-search-box tbody tr td .search-input-box .search-input {
  display: inline-block;
  width: 300px;
  height: 38px;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  margin: 0 0 0 10px;
  padding: 0 12px;
  font-size: 13px;
  font-weight: 400;
  color: #74788D;
  line-height: 38px;
  vertical-align: middle; }

.user-search-box .btn-search {
  display: block;
  margin: 20px 0 0 0;
  text-align: center; }
  .user-search-box .btn-search .search-button {
    height: 38px;
    padding: 0 14px;
    background-color: #343A40;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff; }

.user-list-table .user-pick-list {
  display: block;
  width: 100%;
  border: 1px solid #E8ECF4;
  border-bottom: none;
  box-sizing: border-box;
  padding: 8px 10px; }
  .user-list-table .user-pick-list .pick-list-count {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #74788D;
    line-height: 28px; }
    .user-list-table .user-pick-list .pick-list-count > span {
      font-weight: 700;
      color: #5664D2; }
  .user-list-table .user-pick-list .choice-num-view {
    display: inline-block;
    float: right; }
    .user-list-table .user-pick-list .choice-num-view > select {
      width: 112px;
      height: 28px;
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      padding: 0 20px 0 7px;
      background-position: right 7px center; }

.user-list-table table tr td {
  text-align: center; }
  .user-list-table table tr td .btn-wrap > button {
    display: inline-block;
    height: 28px;
    padding: 0 8px;
    border: 1px solid #343A40;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
    box-sizing: border-box; }
  .user-list-table table tr td .review-count {
    display: inline-block;
    text-align: center; }
    .user-list-table table tr td .review-count .review-count-total {
      position: relative;
      display: inline-block;
      padding: 0 30px 0 0; }
      .user-list-table table tr td .review-count .review-count-total .total-count {
        display: inline-block; }
      .user-list-table table tr td .review-count .review-count-total .btn-review-count-more {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        width: 20px;
        height: 20px;
        background-color: #4285F433;
        border-radius: 3px;
        line-height: 20px; }
        .user-list-table table tr td .review-count .review-count-total .btn-review-count-more .nd-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -8px;
          margin-left: -8px;
          width: 16px;
          height: 16px;
          color: #4285F4;
          -ms-transform: rotateZ(0);
          -webkit-transform: rotateZ(0);
          transform: rotateZ(0); }
    .user-list-table table tr td .review-count .review-count-detail {
      display: block;
      text-align: left; }
  .user-list-table table tr td .review-count.open .review-count-total .btn-review-count-more .nd-icon {
    -ms-transform: rotateZ(90deg);
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg); }

.user-list-table .pagination {
  padding: 20px 0 0 0; }
