.basic-info-box {
  .withdrawal {
    float:right;
    .btn-withdrawal {
      padding:0 10px;
      color: #999999;
    }
  }

}
