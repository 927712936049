.wrap-sign-out .sign-content {
   .sign-form input {
      width: auto;
   }
   width: 640px;
   .sign-content-card {
      h3 {
         margin-bottom: 20px;
      }
      p.welcome-text {
         padding: 70px 0 30px 0;
         text-align: center;
         font-size:23px;
         line-height: 70px;
      }
      .btn-box {
         position: absolute;
         bottom:50px;
         left:0;
         text-align:center;
      }
   }
}