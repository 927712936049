.ModalProductList {
  .group-products-wrap {
    position:relative;
    width: 100%;
    height: 104px;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid #eaeaea;
    border-bottom: none;
    ul.product-list {
      li {
        margin:12px 0px 0px 12px;
        display: inline-block;
        width: 80px;
        height: 80px;
        vertical-align: middle;
        img {
          width: 100%;
          height: 100%;
        }
      }
      li.holder {
        border: 1px dashed #aaaaaa;
        border-radius: 6px;
        color: #a7a7a7;
        font-size: 26px;
        text-align: center;
        line-height: 85px;
      }
    }
  }
  .section-box {
    text-align: center;
    padding: 5px 0;
    background: none;
    border: 1px solid #EFF2F7;
  }
  .product-search-box {
    .form-group {
      margin:0;
      button.btn  {
        margin-left: 10px;
        padding: 10px 20px;
      }
    }
  }
  .section-box:nth-child(1) {
    margin:0;
  }
  .list-scroll-wrap {
    height: 700px;
    overflow: auto;
  }
  table tbody tr td { padding: 10px; }
  .product-thumb {
    img {
      width:39px; height:39px;
    }
  }
  .popup-close {
    text-align: right;
    border-top:1px solid #cccccc;
    padding-top: 17px;
  }
}
