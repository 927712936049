@charset "UTF-8";
/* 좌측 카테고리 */
.aside-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 105px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3); }
  .aside-wrap .aside-menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 105px;
    height: 100%;
    background: #ffffff;
    text-align: left; }
    .aside-wrap .aside-menu .aside-menu-toggle {
      position: relative;
      display: block;
      width: 100%;
      height: 64px;
      background-color: #5664D2;
      text-align: center; }
      .aside-wrap .aside-menu .aside-menu-toggle .toggle-bar {
        position: absolute;
        left: 50%;
        margin-left: -8px;
        display: block;
        width: 16px;
        height: 2px;
        background-color: #ffffff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s; }
      .aside-wrap .aside-menu .aside-menu-toggle .toggle-bar:nth-child(1) {
        top: 25px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s; }
      .aside-wrap .aside-menu .aside-menu-toggle .toggle-bar:nth-child(2) {
        top: 50%;
        margin-top: -1px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s; }
      .aside-wrap .aside-menu .aside-menu-toggle .toggle-bar:nth-child(3) {
        bottom: 25px;
        -webkit-transition: 0.15s;
        -o-transition: 0.15s;
        transition: 0.15s; }
    .aside-wrap .aside-menu > ul {
      display: block;
      padding: 0; }
      .aside-wrap .aside-menu > ul > li {
        display: block;
        padding: 20px 10px; }
        .aside-wrap .aside-menu > ul > li > a {
          font-size: 1.4rem;
          font-weight: 500;
          color: #8e8d8d; }
          .aside-wrap .aside-menu > ul > li > a .nd-icon {
            display: block;
            padding: 0 5px 5px 0;
            margin: 0 auto; }
      .aside-wrap .aside-menu > ul > li.on, .aside-wrap .aside-menu > ul li:hover {
        background: #5664D2;
        box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.3);
        color: #ffffff; }
      .aside-wrap .aside-menu > ul > li.on > a, .aside-wrap .aside-menu > ul li:hover > a {
        color: #ffffff; }
    .aside-wrap .aside-menu > ul.depth1 > li {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .aside-wrap .aside-menu > ul.depth1 > li .material-icons {
        display: block;
        margin-bottom: 7px;
        font-size: 37px; }
      .aside-wrap .aside-menu > ul.depth1 > li > a {
        font-size: 14px; }
  .aside-wrap .aside-submenu {
    width: 220px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: #ffffff;
    text-align: left;
    padding: 20px;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .aside-wrap .aside-submenu .aside-submenu-section {
      position: relative;
      margin: 0 0 0 0;
      display: none; }
      .aside-wrap .aside-submenu .aside-submenu-section > ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; }
        .aside-wrap .aside-submenu .aside-submenu-section > ul > li {
          margin-bottom: 10px; }
          .aside-wrap .aside-submenu .aside-submenu-section > ul > li li {
            display: block; }
            .aside-wrap .aside-submenu .aside-submenu-section > ul > li li a {
              display: block;
              padding: 20px 0 0 20px; }
            .aside-wrap .aside-submenu .aside-submenu-section > ul > li li a:hover {
              color: #5664D2; }
          .aside-wrap .aside-submenu .aside-submenu-section > ul > li li:last-child {
            margin-bottom: 50px; }
        .aside-wrap .aside-submenu .aside-submenu-section > ul > li:first-child {
          /*margin-top:50px;*/ }
    .aside-wrap .aside-submenu .aside-submenu-section.on {
      display: block; }
  .aside-wrap .aside-submenu.open {
    opacity: 1;
    left: 105px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .aside-wrap .aside-submenu.open ul.depth2 li {
      border-radius: 10px; }
      .aside-wrap .aside-submenu.open ul.depth2 li a {
        display: inline-block;
        width: 100%;
        padding: 10px 10px 10px 10px;
        line-height: 30px;
        vertical-align: middle;
        color: #000000;
        font-size: 0.9rem;
        font-weight: 100; }
        .aside-wrap .aside-submenu.open ul.depth2 li a i.material-icons {
          top: 0;
          line-height: 30px;
          vertical-align: middle;
          font-size: 23px;
          margin-right: 15px;
          font-weight: 100; }
    .aside-wrap .aside-submenu.open ul.depth2 li:hover, .aside-wrap .aside-submenu.open ul.depth2 li.on {
      background-color: rgba(0, 0, 0, 0.05); }

.aside-wrap.open-submenu {
  width: 325px; }
