@charset "UTF-8";
/* 메인 상단 */
.content-top-area {
  position: relative;
  margin: 0 0 30px 0; }
  .content-top-area .location {
    display: block;
    text-align: left;
    line-height: 1;
    padding: 0 0 7px 0; }
    .content-top-area .location > span {
      position: relative;
      display: inline-block;
      padding: 0 12px 0 8px;
      font-size: 12px;
      font-weight: 400;
      color: #000000; }
    .content-top-area .location > span:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -5px;
      width: 5px;
      height: 9px;
      background: url("../../images/img/icon_right_arrow.svg") no-repeat; }
    .content-top-area .location > span:first-child {
      padding-left: 0; }
    .content-top-area .location > span:last-child:after {
      display: none; }
  .content-top-area .content-title {
    display: block;
    line-height: 1;
    padding: 7px 0 0 0; }
    .content-top-area .content-title h2 {
      display: inline-block;
      font-size: 24px;
      font-weight: 700;
      color: #000000; }
    .content-top-area .content-title .content-sub-title {
      display: inline-block;
      padding: 0 0 0 10px;
      font-size: 12px;
      font-weight: 200;
      color: #74788D; }
