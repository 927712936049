@charset "UTF-8";
/* 메인 상단 */
.layer-black {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ccc;
  opacity: 0.6; }
  .layer-black.show {
    display: block; }
  .layer-black.hide {
    display: none; }
