@charset "UTF-8";
/* 상단 */
.header {
  position: relative;
  width: 100%;
  height: 64px;
  background: #ffffff;
  padding: 20px 30px 0;
  box-sizing: border-box; }
  .header .top-logo {
    display: inline-block; }
  .header .top-gnb {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -15px;
    display: inline-block; }
    .header .top-gnb .top-notice {
      display: inline-block;
      padding: 0 18px 0 0;
      vertical-align: middle; }
      .header .top-gnb .top-notice .notice-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0; }
        .header .top-gnb .top-notice .notice-icon .nd-icon {
          position: absolute;
          top: 50%;
          left: -10px;
          margin-top: -12px; }
      .header .top-gnb .top-notice .notice-content {
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        vertical-align: middle; }
      .header .top-gnb .top-notice .notice-new {
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
        color: #FF3D60;
        padding: 0 0 0 4px;
        vertical-align: middle; }
    .header .top-gnb .top-user {
      display: inline-block;
      padding: 0 15px 0 18px;
      vertical-align: middle; }
      .header .top-gnb .top-user .nd-icon {
        color: #636E75; }
    .header .top-gnb .top-tool {
      display: inline-block;
      padding: 0 0 0 15px;
      vertical-align: middle; }
      .header .top-gnb .top-tool .nd-icon {
        color: #636E75; }
  .header .setting-box {
    position: absolute;
    top: 50px;
    right: 25px;
    z-index: 100;
    width: 200px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(15, 15, 15, 0.08), 0 5px 10px 0 rgba(15, 15, 15, 0.14), 0 0 0 1px rgba(0, 0, 0, 0.05); }
    .header .setting-box > ul > li {
      padding: 12px 15px 10px 15px;
      border-bottom: 1px solid rgba(15, 15, 15, 0.08);
      line-height: 20px; }
    .header .setting-box .store-info {
      padding: 10px 15px 10px 15px; }
      .header .setting-box .store-info .brand-thumb {
        display: inline-block;
        width: 60px;
        height: 60px; }
      .header .setting-box .store-info .store-name, .header .setting-box .store-info store-id {
        padding: 5px 0; }
    .header .setting-box .service-info {
      padding: 10px 15px 20px 15px;
      border-bottom: 1px solid rgba(15, 15, 15, 0.08); }
      .header .setting-box .service-info h6 {
        color: #000000;
        font-size: 12px;
        font-weight: normal; }
      .header .setting-box .service-info ul li {
        list-style-position: inside;
        list-style-type: disc; }
        .header .setting-box .service-info ul li span {
          display: inline-block;
          margin-left: -7px; }
