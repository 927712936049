/* keyword */
.review-keyword-box{
  position:relative;
  width:100%;
  .btn-keyword{
    position:relative;
    display:block;
    margin:0 0 10px 0;
    .keyword-del{
      display:inline-block;
      height:28px;
      padding:0 8px;
      border:1px solid #343A40;
      border-radius:5px;
      text-align:center;
      font-size:13px;
      font-weight:400;
      color:#000000;
      box-sizing:border-box;
    }
    .keyword-add{
      position:absolute;
      top:50%;
      right:0;
      margin-top:-14px;
    }
  }
  table{

    thead{
      width:100%;
      height:46px;
      background:#EFF2F7;
      text-align:center;
      tr{
        width:100%;
        th{
          font-size:13px;
          font-weight:500;
          color:#000000;
          padding:0 21px;
          border:none;
        }
      }
    }
    tbody{

      tr{
        width:100%;
        td{
          font-size:13px;
          font-weight:400;
          color:#000000;
          text-align:center;
        }
      }
    }
  }
}

/* reviewConnect */
.review-connect-table{
  margin:0 0 20px 0;
  .review-pick-list{
    display:block;
    width:100%;
    border:1px solid #E8ECF4;
    border-bottom:none;
    box-sizing:border-box;
    padding:8px 10px;
    .pick-list-count{
      display:inline-block;
      font-size:12px;
      font-weight:400;
      color:#74788D;
      line-height:28px;
      > span{
        font-weight:700;
        color:#5664D2;
      }
    }
    .choice-num-view{
      display:inline-block;
      float:right;
      > select{
        width:112px;
        height:28px;
        border:1px solid #CED4DA;
        box-sizing:border-box;
        padding:0 20px 0 7px;
        background-position:right 7px center;
      }
    }
  }
  .btn-connect{
    position:relative;
    display:block;
    width:100%;
    border:1px solid #E8ECF4;
    border-bottom:none;
    box-sizing:border-box;
    padding:8px 10px;
    .connect-del{
      position:relative;
      display:inline-block;
      height:28px;
      padding:0 8px 0 20px;
      border:1px solid #343A40;
      box-sizing:border-box;
      border-radius:5px;
      .nd-icon{
        position:absolute;
        top:50%;
        left:5px;
        margin-top:-8px;
        width:16px;
        height:16px;
        color:#FF3D60;
      }
      > span{
        font-size:13px;
        font-weight:400;
        color:#000000;
        line-height:28px;
      }
    }
    .connect-add{
      position:absolute;
      top:50%;
      right:10px;
      margin-top:-14px;
      padding:0 12px 0 32px;
      .nd-icon{
        position:absolute;
        top:50%;
        left:12px;
        margin-top:-7px;
        width:14px;
        height:14px;
        color:#ffffff;
      }
      > span{
        font-size:13px;
        font-weight:400;
        color:#ffffff;
        vertical-align:middle;
        line-height:28px;
      }
    }
  }
  table{

    tbody{

      td{
        text-align:center;
        padding:10px 20px;
        .product-connect-wrap{

          .product-content{
            display:inline-block;
            height:26px;
            padding:0 4px;
            background:#DADBDC;
            border-radius:5px;
            box-sizing:border-box;
            text-align:center;
            line-height:26px;
            margin:0 2px;
            font-size:13px;
            font-weight:400;
            color:#000000;
            vertical-align:middle;
          }
          .product-content:nth-child(1){
            margin-left:0;
          }
          .product-connect{
            position:relative;
            display:inline-block;
            width:20px;
            height:20px;
            background:#4285F433;
            border-radius:5px;
            text-align:center;
            vertical-align:middle;
            margin:0 2px;
            .nd-icon{
              display:inline-block;
              width:12px;
              height:12px;
              color:#4285F4;
            }
          }
          .product-connect.group{

            .nd-icon.left{
              position:absolute;
              top:50%;
              left:0;
              transform:translateY(-50%);
            }
            .nd-icon.right{
              position:absolute;
              top:50%;
              right:0;
              transform:translateY(-50%);
            }
          }
          .product-connect.one-way{

            .nd-icon{
              position:absolute;
              top:50%;
              left:50%;
              transform:translate(-50%, -50%);
            }
          }
        }
        .btn-connect-modify{
          display:inline-block;
          height:28px;
          padding:0 8px;
          text-align:center;
          border:1px solid #343A40;
          border-radius:5px;
          font-size:13px;
          font-weight:400;
          color:#000000;
        }
      }
      td:nth-child(4){
        padding:10px;
      }
    }
  }
  .pagination{
    padding:30px 0 0 0;
  }
}

/* reviewList */
.review-search-box{
  position:relative;
  width:100%;
  padding:0 0 20px 0;
  .btn-toggle {
    position:absolute;
    bottom:0;
    left:50%;
    margin-left:-21px;
    margin-bottom:-21px;
    background-color:#ffffff;
    padding:2px;
    -moz-box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow:0 13px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius:3px;
  }
  input[type="checkbox"]{
    position:relative;
    display:inline-block;
    background:url("../../../images/img/input_checkbox.svg")no-repeat 0 0;
    width:16px;
    height:16px;
    -webkit-appearance:none;
    margin:0 5px 0 0;
    cursor:pointer;
    vertical-align:middle;
  }
  input[type="checkbox"]:checked{
    background:url("../../../images/img/input_checkbox_on.svg")no-repeat 0 0;
  }
  .search-input-box{
    display:block;
    padding:0 0 7px;
    > span{
      display:inline-block;
      width:73px;
      vertical-align:middle;
      font-size:13px;
      font-weight:400;
      color:#000000;
    }
    .link-widget {
      margin-left:10px;
      width:auto;
      a {
        color: #4285F4;
      }
    }
    .search-type{
      display:inline-block;
      width:155px;
      height:38px;
      border:1px solid #CED4DA;
      box-sizing:border-box;
      padding:0 25px 0 12px;
      font-size:13px;
      font-weight:400;
      color:#000000;
      line-height:38px;
      vertical-align:middle;
      cursor:pointer;
    }
    .search-input{
      display:inline-block;
      width:300px;
      height:38px;
      border:1px solid #CED4DA;
      box-sizing:border-box;
      margin:0 0 0 10px;
      padding:0 12px;
      font-size:13px;
      font-weight:400;
      color:#000000;
      line-height:38px;
      vertical-align:middle;
    }
  }
  .calendar-box{
    display:block;
    padding:7px 0 10px;
    > span{
      display:inline-block;
      width:73px;
      vertical-align:middle;
      font-size:13px;
      font-weight:400;
      color:#000000;
    }
    .calendar-wrap{
      display:inline-block;
      font-size:13px;
      font-weight:400;
      color:#000000;
      vertical-align:middle;
    }
    .search-calendar{
      position:relative;
      display:inline-block;
      width:220px;
      height:38px;
      border:1px solid #CED4DA;
      box-sizing:border-box;
      padding:0 12px;
      margin:0 5px 0 0;
      font-size:13px;
      font-weight:400;
      color:#000000;
      line-height:38px;
      background:url("../../../images/img/icon_calendar.svg")no-repeat right 12px center;
      cursor:pointer;
    }
    .search-calendar.end{
      margin:0 0 0 5px;
    }
  }
  .detail-filter-box{
    position:relative;
    padding:10px 0;
    table{
      width:100%;
      border:1px solid #EFF2F7;
      box-sizing:border-box;
      text-align:left;
      border-collapse:collapse;
      tr{
        width:100%;
        height:46px;
        th{
          width:137px;
          font-size:13px;
          font-weight:400;
          color:#343A40;
          border:1px solid #EFF2F7;
          padding:0 0 0 20px;
          box-sizing:border-box;
        }
        td{
          font-size:13px;
          font-weight:400;
          color:#343A40;
          border:1px solid #EFF2F7;
          padding:0 0 0 20px;
          label{
            position:relative;
            display:inline-block;
            padding:0 0 0 20px;
            cursor:pointer;
            vertical-align:middle;
            > span{
              vertical-align:middle;
            }
          }
          > label:nth-child(1){
            padding-left:0;
          }
          label.review-star{

            span{
              display:inline-block;
              width:60px;
              height:11px;
            }
          }
          label.review-star.star05{

            span{
              background:url("../../../images/img/icon_star5.svg")no-repeat 0 0;
            }
          }
          label.review-star.star04{

            span{
              background:url("../../../images/img/icon_star4.svg")no-repeat 0 0;
            }
          }
          label.review-star.star03{

            span{
              background:url("../../../images/img/icon_star3.svg")no-repeat 0 0;
            }
          }
          label.review-star.star02{

            span{
              background:url("../../../images/img/icon_star2.svg")no-repeat 0 0;
            }
          }
          label.review-star.star01{

            span{
              background:url("../../../images/img/icon_star1.svg")no-repeat 0 0;
            }
          }
        }
      }
    }
  }
  .button-area{
    display:block;
    padding:10px 0 0 0;
    .search-filter-toggle{
      position:relative;
      display:inline-block;
      width:94px;
      height:38px;
      border-radius:5px;
      font-size:13px;
      font-weight:400;
      color:#000000;
      padding:0 31px 0 0;
      text-align:right;
      background:#EFF2F7;
      line-height:38px;
      box-sizing:border-box;
      vertical-align:middle;
    }
    .search-filter-toggle:before{
      content:"";
      position:absolute;
      top:50%;
      right:12px;
      margin-top:-8px;
      width:16px;
      height:16px;
      background:url("../../../images/img/icon_up_arrow.svg")no-repeat 0 0;
      background-size:100%;
      -ms-transform:rotateZ(180deg);
      -webkit-transform:rotateZ(180deg);
      transform:rotateZ(180deg);
    }
    .search-filter-toggle.open:before{
      -ms-transform:rotateZ(0);
      -webkit-transform:rotateZ(0);
      transform:rotateZ(0);
    }
    .btn_reset{
      display:inline-block;
      width:88px;
      height:38px;
      border-radius:5px;
      font-size:13px;
      font-weight:400;
      color:#000000;
      border:1px solid #EFF2F7;
      box-sizing:border-box;
      margin:0 0 0 10px;
      line-height:38px;
      vertical-align:middle;
      text-align:center;
    }
  }
  .btn-search{
    display:block;
    position:absolute;
    right:17px;
    bottom:17px;
  }
}
.review-list-box{
  margin:20px 0 0 0;
  table{
    width:100%;
    border:1px solid #EFF2F7;
    box-sizing:border-box;
    text-align:left;
    border-collapse:collapse;
    thead {
      width: 100%;
      height: 46px;
      background: #EFF2F7;
      text-align: center;
      th {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
        padding: 0 21px;
        border: none;
        .custom-control {
          display: inline-block;
        }
      }
    }
    tbody{
      td{
        min-height:auto;
        padding:0;
        display:table-cell;
        border:1px solid #EFF2F7;

        .custom-control {
          display: inline-block;
        }
        .review-info-wrap {
          min-width: 98px;
          padding: 21px;

          .review-num {
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            padding: 0 0 7px 0;
          }

          .user-name {
            position: relative;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            margin: 0 0 10px 0;
            vertical-align: middle;
            white-space: nowrap;

            .member-icon {
              position: relative;
              display: inline-block;
              height: 18px;
              padding: 0 4px;
              box-sizing: border-box;
              text-align: center;
              font-size: 11px;
              font-weight: 400;
              line-height: 18px;
              margin: 0 0 0 5px;
              vertical-align: top;
            }

            .member-icon.npay-member {
              color: #5664D2;
              border: 1px solid #5664D2;
            }

            .member-icon.non-member {
              color: #343A40;
              border: 1px solid #343A40;
            }

            .member-icon.member {
              color: #1CBB8C;
              border: 1px solid #1CBB8C;
            }

            .member-icon.admin {
              color: #FF3D60;
              border: 1px solid #FF3D60;
            }
          }

          .review-type {

            > span {
              display: inline-block;
              height: 18px;
              padding: 0 4px;
              background: #4285F4;
              text-align: center;
              font-size: 11px;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
              margin: 0 0 10px 0;
            }
          }

          .review-history {

            ul {

              li {
                display: block;
                font-size: 11px;
                font-weight: 400;
                color: #999999;
              }
            }

            .history-more {
              display: block;
              padding: 7px 0 0 0;

              .btn-history-more {
                display: block;
                width: 20px;
                height: 14px;
                background: #D9E7FD;
                border-radius: 5px;
                text-align: center;
                line-height: 14px;

                .nd-icon {
                  width: 16px;
                  height: 16px;
                  color: #4285F4;
                  -ms-transform: rotateZ(0);
                  -webkit-transform: rotateZ(0);
                  transform: rotateZ(0);
                }
              }
            }
          }
          .review-history.open {

            .history-more {

              .btn-history-more {

                .nd-icon {
                  -ms-transform: rotateZ(180deg);
                  -webkit-transform: rotateZ(180deg);
                  transform: rotateZ(180deg);
                }
              }
            }
          }
        }
        .review-contents-wrap{

          .order-product-info{
            position:relative;
            padding:21px;
            border-bottom:1px solid #EFF2F7;
            .product-thumb{
              width:30px;
              height:30px;
              vertical-align:middle;
              display: inline-block;
              margin-right: 7px;
              cursor:pointer;
              img{
                width:100%;
                height:100%;
              }
            }
            .product-info{
              display:inline-block;
              vertical-align:middle;
              cursor:pointer;
              .product-name{
                font-size:12px;
                font-weight:400;
                color:#000000;
                display:inline-block;
              }
              .product-option{
                display:inline-block;
                font-size:12px;
                font-weight:400;
                color:#999999;
                padding:0 0 0 10px;
              }
            }
            .product-info-more{
              position:absolute;
              top:21px;
              right:21px;
              .btn-prdinfo-more{
                font-size:12px;
                font-weight:400;
                color:#000000;
                display:inline-block;
                vertical-align:middle;
                .nd-icon{
                  display:inline-block;
                  vertical-align:top;
                  width:16px;
                  height:16px;
                  color:#000000;
                  -ms-transform:rotateZ(0);
                  -webkit-transform:rotateZ(0);
                  transform:rotateZ(0);
                }
              }
            }
          }
          .order-product-info.open{

            .product-thumb{
              display:inline-block;
            }
            .product-info{
              padding:0 0 0 10px;
              .product-option{
                display:block;
                padding:8px 0 0 0;
              }
            }
            .product-info-more{

              .btn-prdinfo-more{

                .nd-icon{
                  -ms-transform:rotateZ(180deg);
                  -webkit-transform:rotateZ(180deg);
                  transform:rotateZ(180deg);
                }
              }
            }
          }
          .review-contents{
            position:relative;
            padding:27px 21px 18px;
            .review-contents-box{
              position:relative;
              width:100%;
              overflow:hidden;
            }
            .review-contents-box:after{
              content:"";
              display:block;
              clear:both;
            }
            .review-contents-area{
              position:relative;
              width:80%;
              float:left;
              .review-contents-top{
                display:block;
                padding:0 0 15px 0;
                .review-star{
                  display:inline-block;
                  padding:0 8px 0 0;
                  > span{
                    display:block;
                    width:60px;
                    height:11px;
                    background:url("../../../images/img/icon_star3.svg")no-repeat 0 0;
                    background-size:cover;
                  }
                }
                .review-star.star01{
                  > span{
                    background:url("../../../images/img/icon_star1.svg")no-repeat 0 0;
                  }
                }
                .review-star.star02{
                  > span{
                    background:url("../../../images/img/icon_star2.svg")no-repeat 0 0;
                  }
                }
                .review-star.star03{
                  > span{
                    background:url("../../../images/img/icon_star3.svg")no-repeat 0 0;
                  }
                }
                .review-star.star04{
                  > span{
                    background:url("../../../images/img/icon_star4.svg")no-repeat 0 0;
                  }
                }
                .review-star.star05{
                  > span{
                    background:url("../../../images/img/icon_star5.svg")no-repeat 0 0;
                  }
                }
                .review-write-date{
                  display:inline-block;
                  font-size:11px;
                  font-weight:400;
                  color:#000000;
                }
                .review-content-length{
                  float:right;
                  font-size:11px;
                  font-weight:400;
                  color:#999999;
                }
              }
              .review-contents-top:after{
                content:"";
                display:block;
                clear:both;
              }
              .review-content{
                font-size: 13px;
                font-weight: 400;
                color: #000000;
                height:70px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin:0 0 10px 0;
                .review-content-textarea {
                  display:inline-block;
                  width:calc(100% - 100px);
                  height:70px;
                  border:1px solid #DDDDDD;
                  box-sizing:border-box;
                  padding:13px 20px 11px;
                }
                .btn-review-content-modify {
                  width:70px;
                  height:70px;
                  border:1px solid #dddddd;
                  border-left:none;
                  text-align:center;
                  vertical-align:middle;
                  box-sizing:border-box;
                  font-size:13px;
                  font-weight:400;
                  color:#000000;
                }
              }
              .review-status{
                display:block;
                padding:0 0 10px 0;
                > div{
                  display:inline-block;
                  font-size:11px;
                  font-weight:400;
                  color:#000000;
                  padding:0 5px 0 0;
                }
                > div:last-child{
                  padding:0;
                }
              }
            }
            .review-photo-area{
              float:right;
              position:relative;
              width:20%;
              text-align:right;
              .review-detail-more{
                display:block;
                padding:0 0 12px 0;
                .btn-review-detail-more{
                  font-size:12px;
                  font-weight:400;
                  color:#000000;
                  display:inline-block;
                  vertical-align:middle;
                  .nd-icon{
                    display:inline-block;
                    vertical-align:top;
                    width:16px;
                    height:16px;
                    color:#000000;
                  }
                }
              }
              .review-photo{
                width: 200px;
                height: 62px;
                overflow: hidden;
                overflow-x: auto;
                ul{
                  text-align:left;
                  white-space:nowrap;
                  li{
                    position:relative;
                    display: inline-block;
                    width:42px;
                    height:42px;
                    margin:0 0 0 4px;
                    .dim{
                      position:absolute;
                      top:0;
                      left:0;
                      width:100%;
                      height:100%;
                      text-align:center;
                      background:#000000;
                      opacity:50%;
                      font-size:12px;
                      font-weight:400;
                      color:#ffffff;
                      vertical-align:middle;
                      display:table;
                      > span{
                        display:table-cell;
                        vertical-align:middle;
                      }
                    }
                    img{
                      width:100%;
                    }
                  }
                }
              }
            }
            .review-comment{
              position:relative;
              width:100%;
              textarea{
                display:inline-block;
                width:calc(100% - 70px);
                height:80px;
                border:1px solid #DDDDDD;
                box-sizing:border-box;
                padding:13px 20px 11px;
              }
              .btn-review-comment{
                width:70px;
                height:80px;
                border:1px solid #dddddd;
                border-left:none;
                text-align:center;
                vertical-align:middle;
                box-sizing:border-box;
                font-size:13px;
                font-weight:400;
                color:#000000;
              }
            }
            .review-contents:after{
              content:"";
              display:block;
              clear:both;
            }
          }
        }
        .review-widget-wrap{
          position:relative;
          padding:21px;
          .review-widget-list{
            display:block;
            padding:0 0 10px 0;
            ul{
              display:block;
              li{
                display:block;
                padding:0 0 5px;
                .review-widget{
                  position:relative;
                  display:inline-block;
                  background:#E0E3F7;
                  border-radius:5px;
                  font-size:12px;
                  font-weight:700;
                  color:#5664D2;
                  padding:3px 31px 3px 10px;
                  .review-widget-tit{
                    display:inline-block;
                    white-space:nowrap;
                  }
                  .review-widget-del{
                    position:absolute;
                    top:50%;
                    right:8px;
                    margin-top:-8px;
                    cursor:pointer;
                    .nd-icon{
                      width:16px;
                      height:16px;
                      color:#5664D2;
                    }
                  }
                }
              }
              li:last-child{
                padding:0;
              }
            }
          }
          .review-widget-select{

            .widget-select{
              display:inline-block;
              width:150px;
              height:38px;
              border:1px solid #CED4DA;
              box-sizing:border-box;
              padding:0 25px 0 12px;
              font-size:13px;
              font-weight:400;
              color:#000000;
              line-height:38px;
              vertical-align:middle;
              cursor:pointer;
            }
          }
          .no-data{
            display:block;
            font-size:13px;
            font-weight:700;
            color:#4285F4;
            padding:0 0 10px 0;
          }
        }
        .review-management{
          position:relative;
          .posting-state-box{
            position:relative;
            display:block;
            padding:21px;
            border-bottom:1px solid #EFF2F7;
            .posting-state{
              font-size:13px;
              font-weight:400;
              color:#000000;
              padding:0 0 10px 0;
              .posting-state-txt{
                color:#4285F4;
              }
            }
            .posting-state-icon{
              position:absolute;
              top:21px;
              right:21px;
              > a{
                display:inline-block;
                width:20px;
                height:20px;
                background:#D9E7FD;
                border-radius:5px;
                text-align:center;
                .nd-icon{
                  width:16px;
                  height:16px;
                  color:#4285F4;
                  vertical-align:middle;
                }
              }
            }
            .posting-state-select{

              .posting-select{
                display:inline-block;
                width:210px;
                height:38px;
                border:1px solid #CED4DA;
                box-sizing:border-box;
                padding:0 25px 0 12px;
                font-size:13px;
                font-weight:400;
                color:#000000;
                line-height:38px;
                vertical-align:middle;
                cursor:pointer;
              }
            }
          }
          .benefits-state-box{
            position:relative;
            display:block;
            padding:21px;
            border-bottom:1px solid #EFF2F7;
            .benefits-state{
              font-size:13px;
              font-weight:400;
              color:#000000;
              padding:0 0 10px 0;
              .benefits-state-txt{
                color:#4285F4;
              }
            }
            .benefits-state-icon{
              position:absolute;
              top:21px;
              right:21px;
              > span{
                display:inline-block;
                width:20px;
                height:20px;
                border-radius:5px;
                text-align:center;
                margin:0 0 0 4px;
                vertical-align:middle;
              }
              > span:nth-child(1){
                margin:0;
              }
              > span.tooltip{
                background:url("../../../images/img/icon_tooltip.svg")no-repeat center #FF3D60;
              }
              > a{
                display:inline-block;
                width:20px;
                height:20px;
                border-radius:5px;
                text-align:center;
                margin:0 0 0 4px;
                vertical-align:middle;
                background:#D9E7FD;
                .nd-icon{
                  width:16px;
                  height:16px;
                  color:#4285F4;
                  vertical-align:middle;
                }
              }
            }
            .benefits-state-select{

              .benefits-select{
                display:inline-block;
                width:210px;
                height:38px;
                border:1px solid #CED4DA;
                box-sizing:border-box;
                padding:0 25px 0 12px;
                font-size:13px;
                font-weight:400;
                color:#000000;
                line-height:38px;
                vertical-align:middle;
                cursor:pointer;
              }
            }
          }
          .review-management-tool{
            position:relative;
            ul{
              display:block;
              li{
                display:inline-block;
                width:25%;
                height:36px;
                border-right:1px solid #EFF2F7;
                border-bottom:1px solid #EFF2F7;
                box-sizing:border-box;
                text-align:center;
                vertical-align:middle;
                line-height:36px;
                cursor:pointer;
                .nd-icon{
                  vertical-align:middle;
                }

              }
              li.disabled{
                cursor:not-allowed;
                .nd-icon{
                  color:#CED4DA;
                }
              }
              li:last-child{
                border-right:none;
              }
              .li.review-modify{

                .nd-icon{
                  width:17px;
                  height:17px;
                }
              }
              .li.review-memo{

                .nd-icon{
                  width:18px;
                  height:18px;
                }
              }
            }
          }
        }
        td:nth-child(1){
          padding:21px 21px 0;
        }
      }
    }
  }
}

/* reviewWright */
.review-write-box{

  .section-box:nth-child(1){
    margin:0 0 30px 0;
  }
  .btn-search{
    display:block;
    position:absolute;
    right:17px;
    bottom:17px;
  }
}
.product-search-box{

  .search-input-box{

    .search-type{
      display:inline-block;
      width:300px;
      height:38px;
      border:1px solid #CED4DA;
      box-sizing:border-box;
      padding:0 25px 0 12px;
      font-size:13px;
      font-weight:400;
      color:#000000;
      line-height:38px;
      vertical-align:middle;
      cursor:pointer;
    }
    .search-input{
      display:inline-block;
      width:300px;
      height:38px;
      border:1px solid #CED4DA;
      box-sizing:border-box;
      margin:0 0 0 10px;
      padding:0 12px;
      font-size:13px;
      font-weight:400;
      color:#74788D;
      line-height:38px;
      vertical-align:middle;
    }
  }
  .check-exhibition{
    display:block;
    padding:10px 0 0 0;
    > label{
      cursor:pointer;
      input[type="checkbox"]{
        position:relative;
        display:inline-block;
        background:url("../../../images/img/input_checkbox.svg")no-repeat 0 0;
        width:16px;
        height:16px;
        -webkit-appearance:none;
        margin:0 5px 0 0;
        cursor:pointer;
        vertical-align:middle;
      }
      input[type="checkbox"]:checked{
        background: url("../../../images/img/input_checkbox_on.svg")no-repeat 0 0;
      }
      > span{
        vertical-align:middle;
        font-size:13px;
        font-weight:400;
        color:#343A40;
      }
    }
  }
}
.product-list-box{
  margin:0 0 20px 0;
  .product-pick-list{
    display:block;
    padding:0 0 10px 0;
    .pick-list-count{
      display:inline-block;
      font-size:12px;
      font-weight:400;
      color:#74788D;
      line-height:28px;
      > span{
        font-weight:700;
        color:#5664D2;
      }
    }
    .choice-num-view{
      display:inline-block;
      float:right;
    }
    select{
      width:112px;
      height:28px;
      border:1px solid #CED4DA;
      box-sizing:border-box;
      padding:0 20px 0 7px;
      background-position:right 7px center;
    }
  }
  .product-pick-list:after{
    content:"";
    display:block;
    clear:both;
  }
  table{

    thead{

      tr{

        th{
          padding:0;
        }
      }
    }
    tbody{
      tr{

        td{
          font-size:14px;
          text-align:center;
          padding:20px;
          .product-thumb{
            display:block;
            width:66px;
            height:66px;
            background:#DDDDDD;
            img {
              width:100%;
              height:100%;
            }
          }
          .product-title{
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-line-clamp:2;
            -webkit-box-orient:vertical;
          }
          .review-normal{
            display:block;
            padding:0 0 3px 0;
          }
          .review-sns{
            display:block;
            padding:3px 0 0 0;
          }
          .btn-review-write{
            height:28px;
            padding:0 8px;
            text-align:center;
            border:1px solid #343A40;
            border-radius:5px;
            font-size:13px;
            font-weight:400;
            color:#000000;
          }
        }
        td:nth-child(3){
          padding:10px;
        }
      }
    }
  }
  .btn-area{
    display:block;
    padding:10px 0 0 0;
    text-align:right;
    .btn-file-upload{
      min-width:78px;
      height:28px;
      padding:0 8px;
      background:#343A40;
      border-radius:5px;
      text-align:center;
      font-size:13px;
      font-weight:400;
      color:#ffffff;
      line-height:28px;
      vertical-align:middle;
    }
  }
}

@media (min-width: 992px) {
  .isReviewHistoryModal.modal-lg {
    max-width: 960px;
  }
}

.history-window-popup .history-popup-contents{
  position:relative;
}
.history-window-popup .history-popup-contents .popup-contents-top{
  margin:0 0 30px 0;
}
.history-window-popup .history-popup-contents h4 {
  font-size:15px;
  padding:17px 0 7px 0;
}
.history-window-popup .history-popup-contents .popup-contents-top table{
  width:100%;
  border-collapse:collapse;
}
.history-window-popup .history-popup-contents .popup-contents-top tr{
  font-size:0;
  text-align:center;
}
.history-window-popup .history-popup-contents .popup-contents-top tr th{
  width:auto;
  font-size:13px;
  font-weight:400;
  color:#000000;
  padding:10px;
  background:#EFF2F7;
  border:1px solid #EAEAEA;
}
.history-window-popup .history-popup-contents .popup-contents-top tr td{
  font-size:13px;
  font-weight:400;
  color:#000000;
  border:1px solid #EAEAEA;
  padding:10px;
}
.history-window-popup .history-popup-contents .popup-contents-top tr td .member-icon{
  position:relative;
  display:inline-block;
  height:18px;
  padding:0 4px;
  box-sizing:border-box;
  text-align:center;
  font-size:11px;
  font-weight:400;
  line-height:18px;
  margin:0 0 0 8px;
  vertical-align:top;
}
.history-window-popup .history-popup-contents .popup-contents-top tr td .npay-member{
  color:#5664D2;
  border:1px solid #5664D2;
}
.history-window-popup .history-popup-contents .popup-contents-top tr td.review-type{
  font-size:0;
}
.history-window-popup .history-popup-contents .popup-contents-top tr td.review-type span{
  display:inline-block;
  height:18px;
  padding:0 4px;
  background:#4285F4;
  text-align:center;
  font-size:11px;
  font-weight:400;
  color:#ffffff;
  line-height:18px;
}
.history-window-popup .history-popup-contents .popup-contents-main{
  margin:0 0 20px 0;
}
.history-window-popup .history-popup-contents .popup-contents-main table{
  width:100%;
  border-collapse:collapse;
}
.history-window-popup .history-popup-contents .popup-contents-main table thead th{
  height:46px;
  background:#EFF2F7;
  text-align:center;
  font-size:13px;
  font-weight:500;
  color:#000000;
  padding:0;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td{
  padding:21px;
  border:1px solid #EAEAEA;
  text-align:center;
  font-size:12px;
  font-weight:400;
  color:#000000;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .posting-state{
  font-weight:700;
  color:#4285F4;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .posting-select{
  display:inline-block;
  width:138px;
  height:38px;
  border:1px solid #CED4DA;
  box-sizing:border-box;
  padding:0 25px 0 12px;
  font-size:13px;
  font-weight:400;
  color:#000000;
  line-height:38px;
  vertical-align:middle;
  margin:8px 0 0 0;
  cursor:pointer;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state{
  font-weight:700;
  color:#4285F4;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list{
  margin:8px 0 0 0;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul{
  display:block;
  font-size:0;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul li{
  display:inline-block;
  padding:7px 12px;
  border:1px solid #EAEAEA;
  border-radius:5px;
  margin:0 4px 0 0;
  font-size:12px;
  font-weight:400;
  color:#000000;
  cursor:pointer;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul li:first-child{
  border:none;
  background:#5664D2;
  color:#ffffff;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .benefits-state-list ul li:last-child{
  margin:0;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .btn-review-memo{
  display:block;
  width:38px;
  height:32px;
  background:#5664D2;
  text-align:center;
  border-radius:5px;
}
.history-window-popup .history-popup-contents .popup-contents-main table tbody td .btn-review-memo .nd-icon{
  width:20px;
  height:20px;
  color:#ffffff;
  vertical-align:middle;
}

.history-window-popup .history-popup-contents .popup-close{
  display:block;
  font-size:0;
}
.history-window-popup .history-popup-contents .popup-close .btn-popup-close{
  display:block;
  width:52px;
  height:38px;
  background:#5664D2;
  border-radius:5px;
  font-size:13px;
  font-weight:400;
  color:#ffffff;
  text-align:center;
  line-height:38px;
  margin:0 auto;
}

.color-picker {
  position:relative;
}
.color-picker-selecter-wrap {
  position:absolute;
  top:41px;
  z-index: 100;
}

.modal-review-write-form textarea.form-control {
  height: 120px;
}
.media-uploaded-box {
  min-height:162px;
  ul {
    li {
      position: relative;
      display: inline-block;
      width: 128px;
      height: 128px;
      margin-left: 10px;
      span.btn-media-remove {
        position:absolute;
        top:3px;
        right:3px;
        background-color: #ffffff;
        padding:3px 6px 1px 6px;
        font-weight: bold;
        cursor:pointer;
      }
    }
  }

}

.skin-list {
  text-align:left;
}
