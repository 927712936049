@charset "UTF-8";
/* 상단 */
.start-guide {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99999;
  width: 300px;
  height: 400px;
  background: #212121;
  color: #ffffff; }
  .start-guide h3, .start-guide p, .start-guide ul {
    padding: 10px 17px; }
  .start-guide h3 {
    font-size: 13px;
    color: #ffffff; }
  .start-guide .prograss-bars {
    padding: 3px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .start-guide .prograss-bars span {
      margin: 0  3px;
      flex: 1;
      overflow: auto;
      background-color: #ffffff;
      height: 5px;
      border-radius: 10px; }
    .start-guide .prograss-bars span.active {
      background-color: #8FC322; }
  .start-guide ul li {
    padding-left: 23px;
    text-indent: -23px;
    list-style-type: none;
    line-height: 22px;
    margin: 7px 0px; }
  .start-guide .nd-icon {
    margin-right: 7px; }
  .start-guide a {
    color: #ffffff; }
  .start-guide .noDisplaySguide {
    position: absolute;
    right: 10px;
    bottom: 10px; }
