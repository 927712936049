.wrap-sign-out {
  height : 100%; width : 100%;
  background-color: #5664D2;
  padding: 300px 0 0 0;

  h3 { text-align: center; }
  .sign-form {
    width : 600px;
    margin : 0 auto;
    input {
      width: 100%;
    }
  }
  .btn-box {
    width : 600px;
    margin : 0 auto;
    text-align: right;
  }

}
