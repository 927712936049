@charset "UTF-8";
/* bg animation */
@keyframes floating {
  0% {
    transform: rotate(0deg) translate(-10px) rotate(0deg); }
  100% {
    transform: rotate(1turn) translate(-10px) rotate(-1turn); } }

.btn-box button {
  margin-left: 17px; }

.btn-box button:first-child {
  margin-left: 0px; }

/* 로그인, 회원가입 공통 */
.wrap-sign-out {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  padding: 0; }
  .wrap-sign-out .sign-content {
    position: relative;
    width: 360px;
    height: 650px; }
    .wrap-sign-out .sign-content .sign-content-bg {
      box-sizing: border-box; }
      .wrap-sign-out .sign-content .sign-content-bg > span {
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont1 {
        top: -100px;
        right: -100px;
        background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
        animation: floating 7s infinite; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont2 {
        width: 20px;
        height: 20px;
        animation: floating 9s infinite;
        top: 150px;
        right: -150px;
        background: #04a9f5; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont3 {
        width: 20px;
        height: 20px;
        animation: floating 7s infinite;
        left: -150px;
        bottom: 150px;
        background: #1de9b6; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont4 {
        left: -100px;
        bottom: -100px;
        background: linear-gradient(-135deg, #899fd4, #a389d4);
        animation: floating 9s infinite; }
    .wrap-sign-out .sign-content .sign-content-card {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      padding: 30px;
      box-sizing: border-box;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); }
      .wrap-sign-out .sign-content .sign-content-card > h3 {
        display: block;
        font-size: 24px;
        font-weight: 700;
        color: #000000;
        padding: 0 0 10px 0; }
      .wrap-sign-out .sign-content .sign-content-card .sign-form {
        display: block;
        padding: 10px 0 10px;
        width: 100%; }
        .wrap-sign-out .sign-content .sign-content-card .sign-form dl {
          width: 100%;
          height: 38px;
          margin: 10px 0 0; }
          .wrap-sign-out .sign-content .sign-content-card .sign-form dl dt {
            width: 100%;
            height: 100%; }
            .wrap-sign-out .sign-content .sign-content-card .sign-form dl dt input {
              width: 100%;
              height: 100%;
              background-color: #ffffff;
              border: 1px solid #CED4DA;
              padding: 0 12px;
              font-size: 12px;
              font-weight: 400;
              color: #74788D;
              box-sizing: border-box; }
        .wrap-sign-out .sign-content .sign-content-card .sign-form dl:first-child {
          margin: 0; }
      .wrap-sign-out .sign-content .sign-content-card .sign-help {
        display: block;
        padding: 0 0 10px; }
        .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-auto {
          display: inline-block;
          font-size: 13px;
          font-weight: 400;
          color: #343A40; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-auto .input-sign-auto {
            width: 16px;
            height: 16px;
            margin: 0 8px 0 0;
            background-size: 16px 16px;
            vertical-align: middle; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-auto .sign-auto-text {
            vertical-align: text-top; }
        .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-util {
          display: inline-block;
          float: right;
          font-size: 13px;
          font-weight: 400;
          color: #000000; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-util > a {
            vertical-align: middle; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-util .bar {
            display: inline-block;
            width: 1px;
            height: 10px;
            background-color: #000000;
            margin: 0 6px;
            vertical-align: middle; }
      .wrap-sign-out .sign-content .sign-content-card .btn-box {
        display: block;
        width: 100%;
        margin: 10px 0 0; }
        .wrap-sign-out .sign-content .sign-content-card .btn-box .save-button {
          display: block;
          width: 100%;
          height: 46px;
          background-color: #5664D2;
          border-radius: 5px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #ffffff;
          cursor: pointer; }
        .wrap-sign-out .sign-content .sign-content-card .btn-box > a {
          display: block;
          width: 100%;
          height: 46px;
          background-color: #ffffff;
          border: 1px solid #5664D2;
          border-radius: 5px;
          box-sizing: border-box;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #5664D2;
          margin: 10px 0 0;
          line-height: 46px; }
        .wrap-sign-out .sign-content .sign-content-card .btn-box .sign-in {
          display: block;
          padding: 40px 0 0;
          font-size: 12px;
          color: #000000;
          text-align: center; }
          .wrap-sign-out .sign-content .sign-content-card .btn-box .sign-in > span {
            color: #74788D;
            margin: 0 6px 0 0; }
          .wrap-sign-out .sign-content .sign-content-card .btn-box .sign-in strong {
            font-weight: 700;
            color: #000000; }
