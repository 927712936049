.group-set-list {
  ul {
    margin-bottom: 17px;
    li {
      position: relative;
      display: inline-block;
      margin-right:27px;
      vertical-align: middle;
      cursor: pointer;
      .material-icons {
        font-size: 23px;
      }
    }
  }
}

.group-products-wrap {
  background-color: #ffffff;
  height: 170px;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  .add-box {
    display: table;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    .material-icons {
      display: table-cell;
      vertical-align: middle;
      font-size: 60px;
      color:#8e8d8d;
    }
  }
  ul.product-list {
    white-space: nowrap;
    li {
      position: relative;
      margin:22px 0px 0px 22px;
      display: inline-block;
      width: 130px;
      height: 130px;
      vertical-align: middle;
      img {
        border: 1px solid #999999;
        width: 100%;
        height: 100%;
      }
    }
    li.holder {
      border: 1px dashed #aaaaaa;
      border-radius: 6px;
      color: #a7a7a7;
      font-size: 50px;
      text-align: center;
      line-height: 140px;
    }
  }
}
.integration-guide-icon-box {
  padding: 60px 0 50px 0;
  font-size: 20px;
  font-weight: bold;
  .material-icons {
    font-size: 70px;
    vertical-align: middle;
  }
}
