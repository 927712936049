textarea.form-control.serviceAgree {
  height: 200px;
}
.serviceAgreeCheck .custom-control {
  padding-left: 2.7rem;
}
.custom-control-description {
  font-size:0.85rem;
  line-height: 1.8rem;
}
.order-org-amount {
  color: #a6a6a6;
  text-decoration: line-through;
}
.order-amount {
  font-size: 1.2rem;
}
.event-text {
  font-size: 1.2rem;
  color: red;
}
.order-info-pbox {
  font-size: 1.3rem;
  padding: 70px;
  text-align: center;
}
.section-box {
  position: relative;
}
.more-produce-view {
  font-size: 1.05rem;
  position: absolute;
  top:27px;
  right: 27px;
  font-weight: bold;
  border:1px solid #0a0c0d;
  padding:7px 12px 5px 12px;
  border-radius: 30px;
  background-color: #0a0c0d;
  color:#e0e0e0;
}
.more-produce-view a {
  color:#e0e0e0;
}
.more-produce-view a:hover {
  color:#ffffff;
}
.btn-save {
  text-align: right;
 }
.terms-privacy {
  height: 350px;
  padding: 20px 10px;
  overflow: auto;
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 17px;
    margin-bottom: 7px;
  }
}
.serviceAgreeCheck {
  margin: 20px 20px 0 0;
  label {
    font-size: 14px;
    margin-left: 5px;
  }
}